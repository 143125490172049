<template>
  <div class="m-1">
    <b-card>
      <form-render :fields="fields" :key="keyFormRender" :form.sync="form" @send="filterList" :buttonSend="buttonSend" :disableAll="!mainEnable"
        containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2 v-step-2-0" class="mb-2">
        <template #buttons>
          <b-button :disabled="!mainEnable" variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter" ><feather-icon icon="RefreshCwIcon"/></b-button>
        </template>
      </form-render>
      <table-render :schema="schema" :rows.sync="rows" :actions="actions" v-if="loading.showData"></table-render>
      <!-- <pagination :pagination="pagination" :noDigits="false" :showSize="true" v-if="loading.showData"/> -->
      <b-skeleton height="40px" width="100%" class="mt-2 mb-2" v-show="loading.first"/>
      <div class="table-render-skeleton v-step-3-0" v-show="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
  <modal-integrations :currentOrder="currentOrder"></modal-integrations>
  <modal-tracking-number-integrations :currentIntegrationToModal="currentIntegrationToModal"></modal-tracking-number-integrations>
  <div class="btn_guide">
    <b-button :disabled="!mainEnable" class="btn-icon rounded-circle" variant="warning" @click="showTour"><feather-icon class="m-1" size="2x" icon="HelpCircleIcon" /></b-button>
  </div>
  <v-tour name="myTour_integrations" :steps="steps" :options="myOptions" :callbacks="myCallbacks"></v-tour>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalIntegrations from './ModalIntegrations.vue'
import ModalTrackingNumberIntegrations from './ModalTrackingNumberIntegrations.vue'
import IntegrationsService from './integrations.service'
import { length } from '@/@core/utils/validations/validations'
export default {
  name: 'list-integrations',
  components: { ModalIntegrations, ModalTrackingNumberIntegrations },
  data() {
    return {
      form: {},
      fields: [
        { name: 'skeleton1', useSkeleton: true },
        { name: 'skeleton2', useSkeleton: true }
      ],
      schema: [],
      rows: [],
      actions: [],
      payloadFilter: {},
      keyFormRender: '',
      buttonSend: { title:'Buscar', icon: 'SearchIcon', color: 'warning' },
      pagination: {
        page: 1,
        total: 40,
        limit: 10
      },
      loading: {
        shippers: true,
        ordersByShipper: true,
        first: false,
        total: false,
        showData: false,
        order: true
      },
      optionsIntegrations: [
        {id: 'shopify', text: 'shopify'},
        {id: 'woocommerce', text: 'woocommerce'},
        {id: 'vtex', text: 'vtex'},
        {id: 'shipedge', text: 'shipedge'}
      ],
      currentOrder: '',
      currentIntegration: {},
      currentIntegrationToModal: {},
      steps: [],
      myOptions: {
        highlight: true,
        enableScrolling: false,
        labels: {
          buttonSkip: 'Salir',
          buttonPrevious: 'Atras',
          buttonNext: 'Siguiente',
          buttonStop: 'Finalizar'
        }
      },
      myCallbacks: {
        onStop: this.cleanDataTour
      },
      integrationsService: new IntegrationsService(this),
      mainEnable: false
    }
  },

  computed: {
    ...mapGetters({
      mySession: 'getSession',
      shippers: 'getShippers',
      ordersByShipper: 'getOrdersByShipper',
      // order: 'getOrder',
      generalLoading: 'getLoading'
    })
  },

  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    generalLoading: {
      handler () {
        this.loading.shippers = !!this.generalLoading.getShippers
        this.loading.ordersByShipper = !!this.generalLoading.getOrdersByShipper
        // this.loading.order = !!this.generalLoading.getOrder
      },
      deep: true
    },

    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },

    shippers () {
      this.setSelectOptions('company', { options: this.shippers.rows })
    },

    ordersByShipper () {
      this.rows = this.ordersByShipper.rows
      if (this.rows) { 
        this.loading.showData = true
        this.loading.first = false
      }
    }

    // order () {
    //   // this.value = JSON.stringify(this.order, undefined, 4)
    //   this.currentOrder = JSON.stringify(this.order, undefined, 4)
    // } 
  },

  mounted() {
    if (this.mySession.id) this.setInitialData()
  },

  methods: {
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldSelect', name: 'ecommerce', label: 'Integraciones', placeholder: 'Nombre de la integracion', clearable: true, options: this.optionsIntegrations, validation: 'required'}
      ]

      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role) || this.$session.get('cas_user').role === 'marketplace') {
        this.fields[0].dependency = 'company'
        this.fields = [
          { fieldType: 'FieldSelect', name: 'company', label: 'Empresa', placeholder: 'Nombre de la empresa', clearable: true, validation: 'required', 
            searchOnType: { fx: this.getShipperOnSearch, nChars: 4, debounce: 300}}
        ].concat(this.fields)

      }
      
      this.schema = [
        {label: 'ID', sortable: true, key: 'company_id'},
        {label: 'Ecommerce', sortable: true, key: 'ecommerce'},
        {label: 'Referencia', sortable: true, key: 'imported_id'},
        // {label: 'Nº de tracking', sortable: true, key: 'tracking_number'},
        // {label: 'Nº de envío', sortable: true, key: 'delivery_id'},  
        {label: 'Acciones', key: 'actions', class: ['text-center', 'v-step-4-0']}
      ]
      this.actions = [
        {action: id => this.openModal(id), icon: 'FileTextIcon', color: 'success', text: 'Detalle de orden' },
        {action: id => this.openModalTNI(id), icon: 'ExternalLinkIcon', color: 'info', text: 'Integraciones', dependency: 'detailIntegrationBoolean' }
      ]
      if (this.shippers && !!this.shippers.length) {
        this.setSelectOptions('company', { options: this.shippers })
      }
      this.stepsTours()
      this.mainEnable = true
    },
    openModal (_id) {
      const orderSelected = this.ordersByShipper.rows.filter(el => el.id === _id)[0]
      const params = {
        company: orderSelected.company_id,
        ecommerce: orderSelected.ecommerce,
        order: _id
      }
      this.$store.dispatch('fetchService', { name: 'getOrder', queryParams: {}, params})
      this.$bvModal.show('modalIntegrations')
    },
    openModalTNI (_id) {
      this.currentIntegration = this.ordersByShipper.rows.filter(order => order.id === _id)[0]
      if (this.currentIntegration.response.length === 1) {
        const identifier = this.currentIntegration.response[0].identifier
        // const id = this.currentIntegration.company_id
        // this.$router.push({name: 'deliveries-details', params: {id, shipper_id, breadcrumb: { id, shipper_id}}})
        window.open(`https://platform.enviame.io/deliveries/${identifier}`, '_blank') // Redireccion en Platform
      } else if (this.currentIntegration.response.length > 1) {
        this.currentIntegrationToModal = this.currentIntegration.response.map((el, index) => {
          return {
            ...el,
            id: this.currentIntegration.response[index].identifier
          }
        })
        this.$bvModal.show('modalTrackingNumberIntegrations')
      }
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    cleanFilter () {
      this.form = {}
    },
    filterList (form) {
      this.payloadFilter = {}
      Object.keys(form).map(key => {
        this.payloadFilter[key] = form[key].id || form[key]
      })
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getOrders()
    },
    getOrders() {
      this.loading.first = true
      if (this.loading.showData === true) this.loading.showData = false
      let params = {}
      if (this.$session.get('cas_user').role === 'ecommerce') {
        params.company = this.$session.get('cas_user').shipper.id
        params.ecommerce = this.form.ecommerce.id
        // ...this.payloadFilter
      } else if (this.$session.get('cas_user').role === 'marketplace') {
        params = {
          company: this.form.company.id,
          ecommerce: this.form.ecommerce.id
        // ...this.payloadFilter
        }
      } else {
        params = {
          company: this.form.company.id,
          ecommerce: this.form.ecommerce.id
        // ...this.payloadFilter
        }
      }
      //   .page: this.pagination.page,
      //   .paginate_by: this.pagination.limit,
      this.$store.dispatch('fetchService', { name: 'getOrdersByShipper', queryParams: {}, params})
    }, 

    getShippers () {
      let params = {}
      const queryParams = { 
        page: 6, 
        paginate_by: 99999
      }
      if (this.$session.get('cas_user').role === 'marketplace') {
        params = {
          organization_id: this.$session.get('cas_user').organization.id
        }
      }
      this.$store.dispatch('fetchService', { name: 'getShippers', queryParams, params })
    },

    getShipperOnSearch (name) {
      let service = 'getShippers'
      let params = null
      if (this.$session.get('cas_user').role === 'marketplace') {
        params = {
          organization_id: this.$session.get('cas_user').organization.id
        }
        service = 'getShippersByOrganization'
      }
      const queryParams = {
        name,
        page: 1,
        paginate_by: 99999
      }
      return this.integrationsService.callService(service, queryParams, params)
        .then(resp => {
          return resp.data.map(el => {
            el.text = `${el.id} - ${el.name1} - ${el.name2}`
            return el
          })
        })
    },

    showTour () {
      this.$tours['myTour_integrations'].start()
    },

    cleanDataTour () {
      this.form = {}
      this.rows = []
      this.loading.showData = false
      this.$bvModal.hide('modalIntegrations')
    },

    stepsTours () {
      this.steps = [
        {
          target: '#company',
          header: {
            title: '<strong>Seleccion de empresa</strong>'
          },
          content: 'Para dar inicio a la busqueda de integraciones, es necesario seleccionar una empresa.'
        },
        {
          target: '#company',
          content: 'Tecleando unas letras de busqueda del nombre de la empresa, podemos seleccionarla.',
          before: () => new Promise((resolve, reject) => {
            this.form.company = { text: '620 - Empresa Prueba api - seller prueba SpA', id: 620}
            this.keyFormRender++
            resolve()
          })
        },
        {
          target: '#ecommerce',
          header: {
            title: '<strong>Seleccion de integración</strong>'
          },
          content: 'Lista desplegable de opciones.'
          // params: { 
          //   placement: 'bottom',
          //   background: '#6E6B7B'
          // }
        },
        {
          target: '#ecommerce',
          content: 'En esta ocasión seleccionamos algúna opción que se muestre en la lista desplegable.',
          before: () => new Promise((resolve, reject) => {
            this.form.ecommerce = { text: 'shopify', id: 'shopify'}
            this.keyFormRender++
            resolve()
          })
        },
        {
          target: '.v-step-2-0',
          header: {
            title: '<strong>Buscar</strong>'
          },
          content: 'Boton de busqueda de integraciones según selecciones',
          params: { 
            placement: 'left',
            highlight: false
          }
        },
        {
          target: '.v-step-3-0',
          header: {
            title: '<strong>Listado de ordenes</strong>'
          },
          content: 'Se muestra información relevante del listado de ordenes, si accionamos el icono de la columna de <strong>acciones</strong>, accederemos al <strong>detalle</strong> de cada orden.',
          params: { 
            placement: 'bottom',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.rows = [
              { company_id: '620', ecommerce: 'shopify', imported_id: '12313325412341' },
              { company_id: '620', ecommerce: 'shopify', imported_id: '12313325654665' },
              { company_id: '620', ecommerce: 'shopify', imported_id: '12313653532323' }
            ]
            this.loading.showData = true
            this.loading.first = false
            resolve()
          })
        },
        {
          target: '.v-step-4-0',
          header: {
            title: '<strong>Detalle</strong>'
          },
          content: 'En esta columna accederas al detalle de cada orden',
          params: { 
            placement: 'right',
            highlight: false
          }
        },
        {
          target: '.modal-body',
          header: {
            title: '<strong>Modal Detalle</strong>'
          },
          content: 'La información que se muestra es el detalle de cada orden',
          before: () => new Promise((resolve, reject) => {
            const data = {
              'body': {
                'admin_graphql_api_id': 'gid://shopify/Order/3649187971254',
                'app_id': 580112,
                'billing_address': {
                  'address1': 'los pelicanos 3961',
                  'address2': 'casa',
                  'city': 'puente alto',
                  'company': null,
                  'country': 'Chile',
                  'country_code': 'CL',
                  'first_name': 'francisco',
                  'last_name': 'huerta',
                  'latitude': null,
                  'longitude': null,
                  'name': 'francisco huerta',
                  'phone': '9 5526 8992',
                  'province': 'Santiago',
                  'province_code': 'RM',
                  'zip': '17485291K'
                }
              }
            }
            this.currentOrder = JSON.stringify(data, undefined, 4)
            this.$bvModal.show('modalIntegrations')
            resolve()
          })
        }
      ]
      if (this.$session.get('cas_user').role === 'ecommerce') {
        const deleteIndex = this.steps.findIndex(el => el.target === '#company')
        if (deleteIndex !== -1) this.steps.splice(deleteIndex, 2)
        
      }
    }
  }
}
</script>

<style lang="scss">

.v-tour__target--highlighted {
  border-radius: 5px;
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
  pointer-events: auto;
  z-index: 9999;
}

.btn_guide {
  position: fixed;
  bottom: 20px;
  left: 50px;
  z-index: 1031;
  .btn {
    box-shadow: 0 1px 20px 1px #f18805 !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}

.v-step {
  background: #ffffff !important;
  border: 1px solid #82868b !important; 
  border-radius: 10px !important;
}

.v-step__header {
    display: flex !important;
    text-align: left !important;
    margin: -1rem -1rem 0.5rem;
    padding: 0.5rem;
    color: #82868b !important;
    background-color: #F9F9F9 !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.v-step__content {
  color: #82868b !important;
}

.v-step__button {
  color: #4a7bbe !important;
  border: .05rem solid #4a7bbe !important;
  border-radius: .3rem !important;
  &:hover {
      background-color: rgba(#4a7bbe, 0.95) !important;
      color: #ffffff !important;
    }
}


</style>